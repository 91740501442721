module directives {
    export module caseManagement {
        interface IOpenCaseDirective extends ng.IScope {
            openCaseData: interfaces.caseManagement.OpenCaseDashboard[];
            openCaseDataFiltered: interfaces.caseManagement.OpenCaseDashboard[];
            isLoading: boolean;
            country: interfaces.applicationcore.IDropdownModel;
            LoadOpenCaseData();
            viewCase(caseId:number);
            loadActiveTariffCountries();
            loadEntityDefaultCountry();
            searchText: string;
            searchChanged();
            replaceAll(str : string, find: string, replace: string):string;
        }

        export class openCaseDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/caseManagement/openCaseView.html';
            scope = {
            }

            public openCaseData: interfaces.caseManagement.OpenCaseDashboard[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private sarsCaseService: interfaces.caseManagement.ISarsCaseService,
                private menuService: interfaces.applicationcore.IMenuService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private entityService: interfaces.applicationcore.IEntityService,
            ) {

            }

            link = ($scope: IOpenCaseDirective, $element: ng.IAugmentedJQuery, $state) => {

                $scope.loadEntityDefaultCountry = () => {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.entityService.getEntityCountry().get({ entityId: resultEntity.Id }, (result: interfaces.applicationcore.ICountry) => {
                            $scope.country = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Code,
                                Description: result.Description,
                                Display: result.Code + " - " + result.Description,
                                Selected: true
                            };
    
                            $scope.LoadOpenCaseData();
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
               

                $scope.LoadOpenCaseData = () => {
                    $scope.isLoading = true;
                    return this.sarsCaseService.getOpenCasesForDashboard($scope.country.Id).query({
                    }, (resultList) => {
                        $scope.openCaseData = resultList;
                        $scope.openCaseDataFiltered = resultList;
                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.loadEntityDefaultCountry();

                $scope.viewCase = (caseId:number) =>{
                  

                    this.menuService.getGTSConnectMenuItem(190).get((result: interfaces.applicationcore.IMenu) => {
                        if (result.url) {
                            result.url += "?Id=" + caseId;
                            var url = this.$state.href("auth.IFrame", { url: result.url });
                            window.open(url, '_blank');
                        } else {
                            this.generalService.displayMessage("Error - you attempted to access a page that you do not have rights to. Please contact your administrator if you think this is incorrect.", Enum.EnumMessageType.Error);
                        }
                    });

                }

                $scope.searchChanged = () =>{
                 
                    $scope.openCaseDataFiltered = [];
                    for (var i = 0; i < $scope.openCaseData.length; i++){    
                       
                        if (JSON.stringify($scope.openCaseData[i]).toLocaleLowerCase().includes($scope.replaceAll($scope.searchText.toLocaleLowerCase(),'/','-')))
                        {
                            $scope.openCaseDataFiltered.push($scope.openCaseData[i]);
                        }
                    }
                }

                $scope.replaceAll = (str, find, replace) => {
                    return str.replace(new RegExp(find, 'g'), replace);
                  }

                $scope.loadActiveTariffCountries = () => {
                    return this.tariffCountryService
                    .getActiveTariffCountries()
                    .query({}, (resultList) => {}).$promise;
                }

            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state, 
                    bsLoadingOverlayService, sarsCaseService,menuService,tariffCountryService,entityService) => new openCaseDirective(generalService, $state, bsLoadingOverlayService, sarsCaseService,menuService,tariffCountryService,entityService);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService", "sarsCaseService","menuService","tariffCountryService","entityService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsOpenCases", openCaseDirective.factory());
    }
}